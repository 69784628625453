<template>
  <div class="user-list">
    <v-dialog v-model="showEventSelection" width="650">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ translations.labels.autologin_event_dialog }}
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mx-auto">
          <v-col>
            <v-form ref="selectEventForm" lazy-validation>
              <v-select
                v-if="showEventSelection"
                v-model="selectedEventAutoLogin"
                name="idEvent"
                label="Event"
                :hint="
                  translations.labels
                    .button_export_users_event_autologin_code_hint
                "
                persistent-hint
                :items="events"
                required
                item-text="label"
                item-value="value"
                return-object
                outlined
                :rules="[showRules]"
                clearable
              ></v-select>
            </v-form>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="exportAutologinCodes">
            {{ translations.labels.button_export }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Action-panel :isReady="isPageReady">
      <div class="user-list__actions-bar">
        <Button-base
          :type="1"
          :text="translations.labels.button_export_users_event_autologin_code"
          :hoverInfo="
            translations.labels.button_export_users_event_autologin_code_hover
          "
          :onClick="showEventSelectionVisibility"
        />
        <Button-base
          :type="1"
          :text="translations.labels.button_export_users"
          :disabledText="translations.labels.button_export_users"
          :hoverInfo="usersExportOnHover"
          :onClick="exportUsers"
          :disabled="pageisExportingUsers"
        />
        <Button-base
          :type="1"
          :text="translations.labels.button_import_users"
          :disabledText="translations.labels.button_import_users"
          :hoverInfo="translations.labels.button_export_users_hover"
          :onClick="importUsers"
          :disabled="pageisExportingAutologinCodes"
        />
        <Button-base
          :type="2"
          :text="translations.labels.button_add_user"
          :hoverInfo="translations.labels.button_add_user_hover"
          :onClick="newItem"
        />
      </div>
    </Action-panel>
    <TableList
      :isReady="isPageReady"
      :headers="headers"
      :items="model"
      :bulkActions="bulkActions"
      :footer-props="footerProps"
      :filters="filters"
      :current-filters.sync="currentFilters"
      :ssp="true"
      :ssp-length="sspLength"
      @update:ssp="updateSsp"
      @update:selecteditems="updateSelectedList"
      :searchFromQuery="getSearchFromQueryString"
    >
      <template v-slot:item.email="{ item }">
          <div class="user-list__columns"
          :title="item.email">
            {{item.email}}
          </div>
        </template>
        <template v-slot:item.lastName="{ item }">
          <div class="user-list__columns"
          :title="item.lastName">
            {{item.lastName}}
          </div>
        </template>
        <template v-slot:item.firstName="{ item }">
          <div class="user-list__columns"
          :title="item.firstName">
            {{item.firstName}}
          </div>
        </template>
    </TableList>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import Component, { mixins } from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import TableAction from "@/components/table/TableAction";
import { Utility } from "@/lib/Utility";
import QueryStringManager from "@/mixins/queryStringManager";

@Component({
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList,
    TableAction,
    ConfirmDialog,
  },
  inject: {
    groupService: "groupService",
    toastService: "toastService",
    userService: "userService",
    eventService: "eventService",
    customFieldService: "customFieldService",
    visitorLevelService: "visitorLevelService",
  },
})
export default class UserList extends mixins(QueryStringManager) {
  isPageReady = false;
  model = [];
  visitorLevels = [];
  groups = [];
  filteredBy = {};
  orderBy = {};
  showEventSelection = false;
  internal_events = [];
  selectedEventAutoLogin = null;
  selectedUsers = [];
  currentFilters = null;
  footerProps = {
    itemsPerPageOptions: [10, 25, 50, 100],
  };
  customFields = [];
  sspLength = 0;
  isExportingUsers = false;
  isExportingAutologinCodes = false;
  maxAutologinCodesExport = 500;
  maxAutologinCodesGeneration = 100;

  get pageisExportingUsers() {
    return this.isExportingUsers;
  }

  get pageisExportingAutologinCodes() {
    return this.isExportingAutologinCodes;
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.user_table_email,
        value: "email",
      },
      {
        text: this.translations.labels.user_table_lastName,
        value: "lastName",
      },
      {
        text: this.translations.labels.user_table_firstName,
        value: "firstName",
      },
      {
        text: this.translations.labels.user_table_lastLoginDate,
        value: "lastLoginDate",
        type: "string",
      },
      {
        text: this.translations.labels.user_table_isVerified,
        value: "isVerified",
        type: "boolean",
        itemValue: (item) => {
          return item.isVerified;
        },
      },
      {
        text: this.translations.labels.user_table_isExpired,
        value: "expirationDate",
        type: "boolean",
        itemValue: (item) => {
          const today = Date.now();
          const parsedExpirationDate = new Date(item?.expirationDate);

          return item.expirationDate != null && parsedExpirationDate <= today;
        },
      },
      {
        text: this.translations.labels.user_table_logs,
        value: "logs",
        type: "link",
        formatValue: (item) => {
          return `/user/logs/${item.id}`;
        },
        icon: "mdi-information-outline",
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        formatValue: (item) => {
          return `/user/detail/${item.id}`;
        },
        icon: "mdi-account-outline",
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/user/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          if (!item.isVerified && !item.isEnabled) {
            alert(this.translations.labels.complete_reg_mail_sent);
          }
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
    ];
  }

  get getSearchFromQueryString() {
    return this.searchFromQueryString;
  }

  get bulkActions() {
    return [
      {
        text: this.translations.labels.table_bulk_enable,
        action: (idList) => {
          this.swapEnabledMultiple(idList, false);
        },
      },
      {
        text: this.translations.labels.table_bulk_disable,
        action: (idList) => {
          this.swapEnabledMultiple(idList, true);
        },
      },
      {
        text: this.translations.labels.table_bulk_generateAutologinCode,
        processableEntitiesMax: this.maxAutologinCodesGeneration,
        action: (idList) => {
          this.generateAutologinCode(idList);
        },
      },
      {
        text: this.translations.labels.table_bulk_addVisitorLevel,
        description:
          this.translations.labels.table_bulkAction_addVisitorLevelDescription,
        itemsLabel: this.translations.labels.table_bulkAction_visitorLevelLabel,
        items: this.visitorLevels,
        action: (idList, idVisitorLevel) => {
          this.addVisitorLevel(idList, idVisitorLevel);
        },
      },
      {
        text: this.translations.labels.table_bulk_removeVisitorLevel,
        description:
          this.translations.labels
            .table_bulkAction_removeVisitorLevelDescription,
        itemsLabel: this.translations.labels.table_bulkAction_visitorLevelLabel,
        items: this.visitorLevels,
        action: (idList, idVisitorLevel) => {
          this.removeVisitorLevel(idList, idVisitorLevel);
        },
      },
    ];
  }

  get filters() {
    return [
      {
        text: this.translations.labels.table_filter_byGroup,
        description: this.translations.labels.table_filter_groupDescription,
        itemsLabel: this.translations.labels.table_filter_groupLabel,
        items: this.groups,
        propToFilter: "idGroups",
      },
      {
        text: this.translations.labels.table_filter_byVisitorLevel,
        description:
          this.translations.labels.table_filter_visitorLevelDescription,
        itemsLabel: this.translations.labels.table_filter_visitorLevelLabel,
        items: this.visitorLevels,
        propToFilter: "idVisitorLevels",
      },
    ];
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get usersExportOnHover() {
    if (this.isExportingUsers) {
      return this.translations.labels.button_export_users_hover_disabled;
    } else {
      return this.translations.labels.button_export_users_hover;
    }
  }

  get autologinCodesExportOnHover() {
    if (this.isExportingAutologinCodes) {
      return this.translations.labels
        .button_export_users_event_autologin_code_hover_disabled;
    } else {
      return this.translations.labels
        .button_export_users_event_autologin_code_hover;
    }
  }

  showRules(v) {
    if (!v) {
      return "Event is required";
    }
  }

  showEventSelectionVisibility() {
    if (this.selectedUsers.length == 0) {
      this.$refs.confirm.open(
        this.translations.errors.somethingWrong,
        this.translations.labels.table_export_noUsersSelected,
        { noconfirm: true }
      );
      return;
    }
    if (this.selectedUsers.length > this.maxAutologinCodesExport) {
      this.$refs.confirm.open(
        this.translations.errors.somethingWrong,
        this.translations.labels.table_export_maximumUsersAutologinCodesExportable.replace(
          "{maxNumber}",
          this.maxAutologinCodesExport
        ),
        { noconfirm: true }
      );
      return;
    }
    this.showEventSelection = true;
  }

  hideEventSelectionVisibility() {
    this.showEventSelection = false;
    this.selectedEventAutoLogin = null;
  }

  updateSelectedList(items) {
    this.selectedUsers = items.map((x) => {
      return x.id;
    });
  }

  newItem() {
    this.$router.push("/user/new");
  }

  async updateSsp(filters, options) {
    const sspFilters = {
      ...filters,
      page: options.page,
      perPage: options.itemsPerPage,
    };
    if ((sspFilters?.orderBy ?? "").startsWith("enable")) {
      sspFilters.orderBy = sspFilters.orderBy.replace("enable", "isEnabled");
    }

    const res = await this.userService.paginatedList(sspFilters);
    this.model = res.result;
    this.model.forEach(element => {
      element.lastLoginDate = Utility.formatDateTime(element.lastLoginDate);
      if(element.lastLoginDate == "NaN-NaN-NaN NaN:NaN"){
        element.lastLoginDate = "No last login";
      }
    });
    this.sspLength = res.meta.totalItems;
  }

  async exportAutologinCodes() {
    if (this.$refs.selectEventForm.validate()) {
      this.isExportingAutologinCodes = true;
      const response = await this.userService.exportAutologinCodes({
        IdEvent:
          Object.keys(this.selectedEventAutoLogin).length > 0
            ? this.selectedEventAutoLogin.value
            : null,
        IdUsers: this.selectedUsers,
      });
      const data = await response.blob();
      const filename =
        new Date()
          .toISOString()
          .substr(0, 16)
          .replace(/[-T:.]/g, "") + "_export.csv";
      Utility.exportDownloadFile(data, filename);
      this.isExportingAutologinCodes = false;
      this.hideEventSelectionVisibility();
    }
  }

  async exportUsers() {
    if (
      confirm(this.translations.labels.button_export_users_confirm_dialog) ==
      true
    ) {
      this.isExportingUsers = true;
      const result = await this.userService.export(this.currentFilters);
      const customFieldsColumns = (
        this?.customFields?.filter((cf) => cf.isEnabled) ?? []
      ).map((cf) => {
        return {
          name: cf.code,
          value: (row, colName) => {
            if (!row?.customFields) {
              return null;
            }
            const target = row.customFields.filter(
              (ucf) => ucf.code === colName
            );
            if (!target.length) {
              return null;
            }
            return target[0].value;
          },
        };
      });

      const columns = [
        "email",
        "firstName",
        "lastName",
        "username",
        "isEnabled",
        "isVerified",
        "eventCode",
        "lastLoginDate",
        "provider",
        "expirationDate",
        "verificationDate",
        "groupReadable",
        "visitorLevelReadable",
        "privaciesReadable",

        ...customFieldsColumns,
      ];

      Utility.exportResults(
        this.translations.exportLabels,
        "users",
        columns,
        result
      );
      this.isExportingUsers = false;
    } else return;
  }

  importUsers() {
    this.$router.push("/user/import");
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? this.toastService.success(this.translations.success.user_disable)
      : this.toastService.success(this.translations.success.user_enable);
  }

  async swapEnabledMultiple(idList, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    try {
      await this.userService.setStatusMultiple(
        {
          idUsers: idList,
        },
        status
      );
      this.model = await this.userService.list();
      this.toastSuccessLabel(isEnabled);
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async swapEnabled(id, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    this.isPageReady = false;
    try {
      await this.userService.setStatus(id, status);
      this.model = await this.userService.list();
      this.toastSuccessLabel(isEnabled);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async addVisitorLevel(idList, idVisitorLevel) {
    if (!idVisitorLevel) {
      this.toastService.error(this.translations.errors.somethingWrong);
      return;
    }

    try {
      await this.userService.setVisitorLevelMultiple({
        idUsers: idList,
        IdVisitorLevel: idVisitorLevel,
      });
      this.model = await this.userService.list();
      this.toastService.success(this.translations.success.user_addVisitorLevel);
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async removeVisitorLevel(idList, idVisitorLevel) {
    if (!idVisitorLevel) {
      this.toastService.error(this.translations.errors.somethingWrong);
      return;
    }

    try {
      await this.userService.removeVisitorLevelMultiple({
        idUsers: idList,
        IdVisitorLevel: idVisitorLevel,
      });
      this.model = await this.userService.list();
      this.toastService.success(
        this.translations.success.user_removeVisitorLevel
      );
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async generateAutologinCode(idList) {
    try {
      await this.userService.generateMultipleAutologinCode({
        IdUsers: idList,
      });
      this.toastService.success(
        this.translations.success.user_generateAutologinCode
      );
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async init() {
    try {
      // this.model = await this.userService.list();
      this.internal_events = await this.eventService.list();
      this.customFields = await this.customFieldService.list();
      this.visitorLevels = await this.visitorLevelService.list();
      if (this.visitorLevels?.length) {
        this.visitorLevels = this.visitorLevels.map((x) => {
          return { text: x.code, value: x.id };
        });
      }

      this.groups = await this.groupService.list();
      if (this.groups?.length) {
        this.groups = this.groups.map((x) => {
          return { text: x.name, value: x.id };
        });
      }

      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.users_list
    );
  }
}
</script>

<style lang="scss" scoped>
.user-list {
  &__actions-bar {
    float: right;

    & > button {
      margin-left: 15px;
    }
  }

  &__columns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

::v-deep .v-card__actions {
  display: inline-block;
}

::v-deep .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}
</style>
